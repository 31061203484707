import './css/style.css';
import './css/signin.css';
import './css/bootstrap.min.css';
import "./node_modules/ol/ol.css";

import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Overlay from 'ol/Overlay';
import {LineString,Point} from 'ol/geom';
import {Style, Fill,Circle, Stroke} from 'ol/style';
import Feature from 'ol/Feature';
import * as olProj from 'ol/proj';

// Global data
var map,
    overlay,
    layerTracks,
    popupDate, popupDateLoc, popupLon, popupLat;

   if (document.domain == "localhost"){
      var baseURL =  'http://localhost/tracking'
   }else{
      var baseURL =  '.'
   }
   
   const store = {};
   const form = document.forms[0];

   var isMobile = window.matchMedia("only screen and (max-width: 760px)");
   
   // Inserts the jwt to the store object
   store.setJWT = function (data) {
      this.JWT = data;
   };

   $("#frmLogin" ).submit(function( e ) {

      e.preventDefault();

      //-----------------------------------------------------------------------
      // Get JWT token
      //-----------------------------------------------------------------------
      $.ajax({
         url: baseURL + '/authenticate.php',          // Replace by https in production
         async: false,
         crossDomain: true,
         //dataType: "json",
         headers: {
            'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
         },
         body: JSON.stringify({
          username: form.inputEmail.value,
          password: form.inputPassword.value
         }),
         success: function(data) {
            const jwt = data;
            store.setJWT(jwt);
            //$('#frmLogin').css('visibility', 'hidden');
            $('#frmLogin').remove();
            setupMap();
            setupTracklist();
            $('#page-map').css('visibility', 'visible');
         },
         error: function( jqXHR, textStatus, errorThrown) {
           alert('There was some error performing the AJAX call!');
           alert(textStatus+'\n'+errorThrown);
         }
      });

   });
   
function setupMap (){
   //-----------------------------------------------------------------------
   // Setup Map
   //-----------------------------------------------------------------------
   map = new Map({
     target: 'map',
     layers: [
       new TileLayer({
         source: new OSM()
       })
     ],
     view: new View({
       center: [0, 0],
       zoom: 2,
       constrainResolution: true,   // necessary to prevent blurry map on zooming
       maxZoom: 19,
     })
   });

   //-----------------------------------------------------------------------
   // Add track layer + source
   //-----------------------------------------------------------------------
   var sourceTracks = new VectorSource({ });
   
   layerTracks = new VectorLayer({
       source: sourceTracks,
   });

   map.addLayer (layerTracks);

   //-----------------------------------------------------------------------
   // Add popup layer
   //-----------------------------------------------------------------------
   const popupContainer = document.getElementById('popup');
   const popupCloser = document.getElementById('popup-closer');
   popupDate    = document.getElementById('popup-date');
   popupDateLoc = document.getElementById('popup-date-location');
   popupLon     = document.getElementById('popup-lon');
   popupLat     = document.getElementById('popup-lat');

   overlay = new Overlay({
      element: popupContainer,
      autoPan: true,
      autoPanAnimation: {
         duration: 250,
      },
   });

   map.addOverlay(overlay);


   // Add a click handler to hide the popup.

   popupCloser.onclick = function () {
     overlay.setPosition(undefined);
     popupCloser.blur();
     map.on('pointermove', clickHandler);
     return false;
   };

   // Add a click handler to the map to render the popup.

   map.on(['singleclick','pointermove'], clickHandler);
   //map.on(['singleclick'], clickHandler);

}


//-----------------------------------------------------------------------
// Display list of days with location data
//-----------------------------------------------------------------------
function setupTracklist () {
   $.ajax({
      url: baseURL + '/getTrackList.php',
      //async: false,
      crossDomain: true,
      dataType: "json",
      headers: {
         'Authorization': `Bearer ${store.JWT}`
      },
     success: function(data) {
         $.each( data, function( key, val ) {
            var checkBox = $( '<div class="checkbox"><input type="checkbox" class="track" date="'+val['date']+'"/></div>'
                             +'<div class="checkbox-text"><p>'+val['date']+'</p></div>');
            checkBox.appendTo('#trackSelect');
         });
      },
      error: function( jqXHR, textStatus, errorThrown) {
        alert('There was some error performing the AJAX call!');
        alert(textStatus);
        alert(errorThrown);
      }
   });

   //--------------------------------------------------------
   // Track selection
   //--------------------------------------------------------
   // Set all checkboxes to unchecked on startup
   $('input[type=checkbox][class=track]').each(
      function() {
      $(this).prop('checked', false);
     }
    );
   $('#trackSelect').click(function() {
      $('input[type=checkbox][class=track]').each(function() {
         if ($(this).is(':checked')) {
            // checked item
               addTrack($(this).attr('date'));
         }else {
             // unchecked item
              removeTrack($(this).attr('date'));
         }
      });
    });
}

//------------------------------------------------------------------------------
// Display track on map
//------------------------------------------------------------------------------
function addTrack(dt) {
   var i;

   var source = layerTracks.getSource();
   var features = source.getFeatures();
   for (i = 0; i < features.length; i++) {
      if (features[i].get('date') === dt) {
         return
      }
   }
   
   $.ajax({
      url: baseURL + '/getTrack.php',
      type: "POST",
      //async: false,
      dataType: "json",
      data: ({date: dt}),
      headers: {
         'Authorization': `Bearer ${store.JWT}`
      },
      success: function(data) {
         // Add track to map:
         var lineCoords = [];
 
         $.each( data, function( key, val ) {
            lineCoords.push(olProj.transform([val['lon'],val['lat']], 'EPSG:4326','EPSG:3857'));
         });
         var trackFeature = new Feature({
               geometry: new LineString(lineCoords)
         });
         var lineStyle = new Style({    
            stroke: new Stroke({
               color: 'rgba(255,0,0,1.0)',
               width: 4
            }),
            zIndex: 150,
         });
         trackFeature.setStyle(lineStyle);
         trackFeature.set('date', dt)
         source.addFeature(trackFeature);
         var sourceTmp = new VectorSource({ });
         sourceTmp.addFeature(trackFeature);
         var extent = sourceTmp.getExtent();
         map.getView().fit(extent, map.getSize());
 
         // Add position markers to map:
         var markerStyleStd = new Style({    
            image: new Circle({
               radius: 5,
               fill: new Fill({color: 'rgba(255,0,0,1.0)'})
            }),
            zIndex: 100,
         });
         var markerStyleLast = new Style({    
            image: new Circle({
               radius: 5,
               fill: new Fill({color: 'rgba(0,255,0,1.0)'})
            }),
             zIndex: 200,
         });
         $.each( data, function( key, val ) {
            var trackFeature = new Feature({
                  geometry: new Point(olProj.transform([val['lon'],val['lat']], 'EPSG:4326','EPSG:3857'))
            });
            if (key != data.length-1){
               trackFeature.setStyle(markerStyleStd);
            }else{
               trackFeature.setStyle(markerStyleLast);
            }
            trackFeature.set('date', dt);
            trackFeature.set('datetime', val['dt']);
            trackFeature.set('lon', val['lon']);
            trackFeature.set('lat', val['lat']);
            trackFeature.set('created_at', val['created_at']);
            source.addFeature(trackFeature);
         });
      },
      error: function( jqXHR, textStatus, errorThrown) {
        alert('There was some error performing the AJAX call!');
        alert(textStatus);
        alert(errorThrown);
      }
   });
}

function removeTrack(dt) {
   var i;
   var features = layerTracks.getSource().getFeatures();
   for (i = 0; i < features.length; i++) {
      if (features[i].get('date')) {
         if (features[i].get('date') == dt) {
            layerTracks.getSource().removeFeature(features[i]);
         }
      }
   }
}


//------------------------------------------------------------------------------
// Click Handler
//------------------------------------------------------------------------------
function clickHandler (evt) {
   var feature, features = [];
   var hitTol = 8;
   if (isMobile.matches){
      hitTol = 15;
   }
   map.forEachFeatureAtPixel(evt.pixel,
      function(feature) {
          features.push(feature);
   }, {hitTolerance: hitTol});

   for (var i = 0, ii = features.length; i < ii; ++i) {
      if (feature) {
         var t1 = feature.getGeometry().getType();
         var t2 = features[i].getGeometry().getType();
         if (t1 == 'LineString' && t2 == 'Point') {
            feature = features[i];
         }else if (t1 == 'Point' && t2 == 'Point') {
            // Display data of most recent track point in case of nearby points:
            if (features[i].getGeometry()['created_at'] > feature.getGeometry()['created_at']){ 
               feature = features[i];
            }
         }
      } else {
         feature = features[i];
      }
   }
   if (feature) {
      if (evt.type === 'singleclick') {
         // Disable popup on hover
         map.un('pointermove', clickHandler);
      }
      var prop = feature.getProperties();
      if (feature.getGeometry().getType() == 'Point'){
         popupDate.innerHTML = prop['created_at'];
         popupDateLoc.innerHTML = prop['datetime'];
         popupLon.innerHTML = prop['lon'];
         popupLat.innerHTML = prop['lat'];
      }else{
         popupDate.innerHTML = prop['date'];
         popupLon.innerHTML = '';
         popupLat.innerHTML = '';
      }
      overlay.setPosition(evt.coordinate);
   }else{
      overlay.setPosition(undefined);
      map.on('pointermove', clickHandler);
   }
      
}
